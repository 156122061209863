import React, { FunctionComponent, ReactNode } from 'react';
import DataRecordTable from 'src/libraries/data-record-table';
import Translate from 'ui/atoms/translate';
import Icon from 'ui/atoms/icon';
import { AdminApi, Campaign } from 'api';
import Currency from 'ui/atoms/currency';
import { ProductCampaignsStatus } from 'apps/issuer/pages/product-details/campaigns/shared/product-campaign-status';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import WithDataRecord from 'src/hoc/WithDataRecord';
import DataRecordOrdering from 'src/ui/types/data-ordering';
import Section from 'src/ui/atoms/section';
import { PaginationProps } from 'ui/molecules/pagination';

export interface ProductCampaignsTableProps {
  productId: string;
  title?: ReactNode;
  emptyView?: ReactNode;
  loading?: boolean;
  paginationProps?: PaginationProps;
  goToDetails: (campaignId: string) => void;
  defaultLimit?: number;
  defaultOrdering?: DataRecordOrdering;
}

const ProductCampaignsTable: FunctionComponent<ProductCampaignsTableProps> = WithDataRecord<AdminApi, Campaign>(
  AdminApi,
)(
  ({ data, ordering, onOrderBy, title, emptyView, loading, goToDetails, paginationProps }) => {
    return (
      <>
        <Section spacing="medium">
          <Translate name={`issuerProductDetails.campaigns.${data?.length ? 'subtitle' : 'subtitleNoData'}`} />
        </Section>
        <DataRecordTable
          data={data}
          ordering={ordering}
          onOrderBy={onOrderBy}
          title={title}
          loading={loading}
          paginationProps={paginationProps}
          emptyView={emptyView}
          cells={[
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.reference`} />,
              render: (campaign) => campaign.name || '-',
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.distributionPlatform`} />,
              render: (campaign) => campaign.distributionPlatform || '-',
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.allocation`} />,
              alignContent: 'right',
              render: (campaign) => campaign.allocation,
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.signed`} />,
              alignContent: 'right',
              render: (campaign) => `${campaign.signedPercentage.toFixed()}%`,
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.pricePerUnit`} />,
              alignContent: 'right',
              render: (campaign) => <Currency decimals={'*'}>{campaign.pricePerToken}</Currency>,
            },
            {
              title: <Translate name={`issuerProductDetails.campaigns.table.status`} />,
              orderingFieldName: 'active',
              render: (campaign) => (
                <PlaceholderFallback>
                  <ProductCampaignsStatus active={campaign.active} />
                </PlaceholderFallback>
              ),
            },
            {
              title: '',
              render: ({ id }) => (
                <Icon name="arrow-right" color="primary" onClick={() => goToDetails(id)} size="large" />
              ),
              alignContent: 'center',
            },
          ]}
        />
      </>
    );
  },
  (api, props, offset, limit, ordering) => {
    return api.adminCampaignsList({
      tokenId: props.productId,
      offset,
      limit,
      ordering,
      ...props,
    });
  },
);

export default ProductCampaignsTable;
