import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import useApiCall from 'hooks/use-api-call';
import { AdminApi, UsersApi } from 'api/apis';
import LoadingRing from 'ui/atoms/loading-ring';
import {
  AdminInvestor,
  AdminInvestment,
  InvestmentTokenForInvestor,
  Identification,
  BenefitingPerson,
  LegalPerson,
  InvestmentTokenPermissionEnum,
  DistributionPlatform,
} from 'api/models';
import BoxedContent from 'ui/molecules/boxed-content';
import StudioInvestmentInvestorDetails from 'src/apps/issuer/shared/studio-investment-investor-details';
import Section from 'ui/atoms/section';
import KycAndCompliance, { KycAndComplianceType } from 'apps/issuer/shared/kyc-and-compliance-view';
import UserData from './user-data-view';
import InvestmentsView from './investments-view';
import Link from 'core/routing/link';
import EmailHistoryView from './emails-history';
import Button from 'ui/atoms/button';
import * as Styled from './styled';
import DeleteAccountModal from './delete-account-modal';
import StatusTag from 'ui/atoms/status-tag';
import Spacer from 'ui/atoms/spacer';
import Info from 'ui/molecules/info';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import moment from 'moment';
import ReactivationModal from './reactivation-modal';
import { PersonType } from 'ui/types/person';
import Grid, { Col } from 'ui/atoms/grid';
import Header from 'src/ui/atoms/header';
import { hasManageIdentificationDataPermission } from '../../helpers/distributionPlatformPermissions';

interface IssuerInvestorDetailsPageProps {
  investorId: string;
}

const IssuerInvestorDetailsPage: FunctionComponent<IssuerInvestorDetailsPageProps> = ({ investorId }) => {
  const [investor, setInvestor] = useState<AdminInvestor>();
  const [benefitingPersons, setBenefitingPersons] = useState<BenefitingPerson[]>();
  const [investments, setInvestments] = useState<AdminInvestment[]>();
  const [identification, setIdentification] = useState<Identification>();
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  const [isReactivationModalOpen, setIsReactivationModalOpen] = useState(false);
  const [isAccountDeactivated, setIsAccountDeactivated] = useState(false);
  const [createdAt, setCreatedAt] = useState('');
  const [earliestDeletionDatetime, setEarliestDeletionDatetime] = useState('');
  const [email, setEmail] = useState('');
  const [associatedDistributionPlatforms, setAssociatedDistributionPlatforms] =
    useState<Array<DistributionPlatform> | null>(null);

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const usersApi: UsersApi = useMemo(() => makeAuthenticatedApi(UsersApi), [makeAuthenticatedApi]);

  const translate = useTranslateWithStringArgs();

  const formatDate = (date: Date) => moment(date).format(`${translate('date.dateFormat')}`);

  useEffect(() => {
    withApi(async () => {
      const { associatedDistributionPlatforms } = await usersApi.usersMeRetrieve();
      setAssociatedDistributionPlatforms(associatedDistributionPlatforms);
    });
  }, [usersApi, withApi]);

  const fetchInvestorDetails = useCallback(() => {
    withApi(async () => {
      const investorDetails = await adminApi.adminInvestorsRetrieve({ id: investorId });
      setInvestor(investorDetails);
      const investmentsData = await adminApi.adminInvestmentsList({ investor: investorId, limit: 100 });
      setInvestments(investmentsData?.results);
      const identification = await adminApi.adminInvestorsIdentificationsBestCurrentRetrieve({ id: investorId });
      setIdentification(identification);
      if (investorDetails?.person.personType === PersonType.Legal) {
        const response = await adminApi.adminInvestorsBenefitingPersonsList({ id: investorId });
        if (response.results) {
          setBenefitingPersons(response.results);
        }
      }
    });
  }, [investorId]);

  const fetchInvestorDeletion = useCallback(() => {
    withApi(async () => {
      try {
        const {
          createdAt,
          earliestDeletionDatetime,
          requestingUser: { email },
        } = await adminApi.adminInvestorsPendingDeletionRetrieve({ id: investorId });

        setCreatedAt(formatDate(createdAt));
        setEarliestDeletionDatetime(formatDate(earliestDeletionDatetime));
        setEmail(email);
        setIsAccountDeactivated(true);
      } catch (e) {
        setIsAccountDeactivated(false);
      }
    });
  }, [investorId]);

  useEffect(() => {
    fetchInvestorDetails();
    fetchInvestorDeletion();
  }, [withApi, adminApi, investorId]);

  const onDeleteModalClose = () => {
    setIsDeleteAccountModalOpen(false);
    fetchInvestorDeletion();
  };

  const onReactivationModalClose = () => {
    setIsReactivationModalOpen(false);
    fetchInvestorDeletion();
  };

  const restartIdentification = () => {
    if (!identification) return null;

    withApi(async () => {
      await adminApi.adminInvestorsIdentificationsRestartCreate({
        id: investorId,
        identificationRequest: {
          ...identification,
          legitimationProtocolId: identification.legitimationProtocol?.id || '',
        },
      });
      fetchInvestorDetails();
    });
  };

  let tokens: InvestmentTokenForInvestor[] = investments
    ? investments?.map((investment) => {
        return investment?.token;
      })
    : [];

  const canManage = investor?.permissions?.length
    ? investor.permissions.includes(InvestmentTokenPermissionEnum.ManageData)
    : false;

  return (
    <>
      <Section spacing="medium">
        <WideContent>
          <Styled.HeaderContainer>
            <Header size="large">
              <Translate name="dashboardIssuerInvestorDetails.title" />
            </Header>
            <Spacer x={4} />
            {isAccountDeactivated && (
              <Styled.SpaceBetweenContainer>
                <StatusTag variant="danger" truncate={true}>
                  <Translate name="dashboardIssuerInvestorDetails.deleteAccount.deactivated" />
                </StatusTag>
                <Styled.ActivateAccountContainer>
                  <Button variant="secondary" color="danger" onClick={() => setIsReactivationModalOpen(true)}>
                    <Translate name="dashboardIssuerInvestorDetails.activateAccount.title" />
                  </Button>
                  <Spacer x={2} />
                  <Info positions={['right', 'top']}>
                    <div>
                      <div>
                        <Translate
                          name="dashboardIssuerInvestorDetails.futureDeletion.requestedContent1"
                          args={[createdAt, email]}
                        />
                      </div>
                      <div>
                        <Translate
                          name="dashboardIssuerInvestorDetails.futureDeletion.requestedContent2"
                          args={[earliestDeletionDatetime]}
                        />
                      </div>
                    </div>
                  </Info>
                </Styled.ActivateAccountContainer>
              </Styled.SpaceBetweenContainer>
            )}
          </Styled.HeaderContainer>

          {!investor && <LoadingRing />}
          {investor && identification && (
            <>
              <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
                <StudioInvestmentInvestorDetails
                  name={investor.companyName || investor.name}
                  email={investor.email}
                  investorId={investor.id}
                  activeInvestmentsNo={investor.activeInvestmentsNo}
                  activeInvestmentsSum={investor.activeInvestmentsSum}
                  isLegalPerson={investor.person.personType === PersonType.Legal}
                  custodianBank={(investor.person as LegalPerson).custodianBank}
                />
              </BoxedContent>
              <Grid spacing="large">
                <Col>
                  <BoxedContent
                    title={<Translate name="kycComplianceDetails.title" />}
                    releaseSpaceWhenTitleIsEmpty={true}
                  >
                    <KycAndCompliance
                      type={KycAndComplianceType.INVESTOR}
                      kycStatus={investor.kycStatus === 'success' ? 'success' : 'required'}
                      hasCompliance={tokens[0]?.compliance || false}
                      comment={investments?.length ? investments[0].investorCompliance?.comment : undefined}
                      complianceStatus={investments?.length ? investments[0].investorCompliance?.status : undefined}
                      complianceDate={
                        investments?.length ? investments[0].investorCompliance?.complianceDate : undefined
                      }
                      investorId={investor.id}
                      hasManageDataPermission={canManage}
                      personData={investor.person as LegalPerson}
                      loadData={fetchInvestorDetails}
                      benefitingPersons={benefitingPersons}
                    />
                  </BoxedContent>
                </Col>
              </Grid>
              <UserData
                investor={investor}
                benefitingPersons={benefitingPersons}
                canManage={canManage}
                fetchInvestorDetails={fetchInvestorDetails}
                restartIdentification={restartIdentification}
                investments={investments}
                identification={identification}
                canAddIdentification={hasManageIdentificationDataPermission(associatedDistributionPlatforms)}
              />
            </>
          )}
        </WideContent>
      </Section>
      {investor && !!investments?.length && (
        <>
          <InvestmentsView
            fetchInvestorDetails={fetchInvestorDetails}
            investments={investments}
            investorId={investorId}
          />
          <EmailHistoryView investorId={investorId} hasManageDataPermission={canManage} />
        </>
      )}
      <WideContent>
        <Styled.SpaceBetweenContainer>
          <Link variant="secondary" to="/dashboard/issuerInvestors">
            <Translate name="dashboardIssuerInvestorDetails.goToInvestors" />
          </Link>
          {canManage && !isAccountDeactivated && (
            <Button variant="secondary" color="danger" onClick={() => setIsDeleteAccountModalOpen(true)}>
              <Translate name="dashboardIssuerInvestorDetails.deleteAccount.delete" />
            </Button>
          )}
        </Styled.SpaceBetweenContainer>
      </WideContent>

      {isDeleteAccountModalOpen && investor && (
        <DeleteAccountModal investor={investor} onModalClose={() => onDeleteModalClose()} />
      )}

      {isReactivationModalOpen && investor && (
        <ReactivationModal investor={investor} onModalClose={() => onReactivationModalClose()} />
      )}
    </>
  );
};

export default WithAuthenticatedPage(IssuerInvestorDetailsPage, AppType.ISSUER);
