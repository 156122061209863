import React, { FunctionComponent } from 'react';
import Translate from 'ui/atoms/translate';
import BoxedContent from 'ui/molecules/boxed-content';
import { extractNaturalAndLegalFromPerson } from 'core/api/conversions';
import Button from 'ui/atoms/button';
import ActionButtons from 'ui/molecules/action-buttons';
import {
  NaturalPerson,
  LegalPerson,
  AdminInvestment,
  Identification,
  AdminInvestor,
  BenefitingPerson,
  KycStatusEnum,
} from 'api';
import { PersonType } from 'ui/types/person';
import useEditInvestorData from 'hooks/use-edit-investor-data';
import InvestorEditModals from 'apps/issuer/pages/investor-details/edit-modals';
import useUserData from './use-user-data';
import IssuerUserInformation from './user-information';
import WalletsInformation from './wallets-information';

interface UserDataViewProps {
  investor: AdminInvestor;
  benefitingPersons?: BenefitingPerson[];
  canManage: boolean;
  fetchInvestorDetails: () => void;
  restartIdentification: () => void;
  investments?: AdminInvestment[];
  identification?: Identification;
  canAddIdentification: boolean;
}

const UserDataView: FunctionComponent<UserDataViewProps> = (props) => {
  const {
    investor,
    canManage,
    fetchInvestorDetails,
    investments,
    identification,
    benefitingPersons,
    restartIdentification,
    canAddIdentification,
  } = props;

  const investorDataProps = useEditInvestorData();

  const { naturalPerson, legalPerson } = extractNaturalAndLegalFromPerson(investor?.person);

  const rootPerson = investor?.person.personType === PersonType.Legal ? legalPerson : naturalPerson;

  const userSections = useUserData({
    ...investorDataProps,
    investor,
    rootPerson,
    naturalPerson,
    legalPerson,
    identification,
    benefitingPersons: benefitingPersons,
    canReidentify: investor.canReidentify,
    canAddIdentification,
    restartIdentification: restartIdentification,
  });

  return (
    <>
      {userSections.map((userDataSet) => {
        if (!userDataSet) {
          return null;
        }
        const editButtons = userDataSet.editButtons;

        if (userDataSet.key === 'walletsData') {
          return (
            <WalletsInformation investorId={investor.id} isKycSuccess={investor?.kycStatus === KycStatusEnum.Success} />
          );
        }
        return (
          <BoxedContent
            title={<Translate name={`dashboardInvestorSettings.${userDataSet.key}.title`} />}
            releaseSpaceWhenTitleIsEmpty={true}
            key={userDataSet.key}
          >
            <>
              <IssuerUserInformation
                userDataSet={userDataSet}
                userType={'issuer'}
                personType={investor?.person.personType as any} // TODO(mara-cashlink): replace any
                naturalPerson={naturalPerson as NaturalPerson} // TODO(mara-cashlink): replace any
                legalPerson={legalPerson as LegalPerson} // TODO(mara-cashlink): replace any
              />
              <ActionButtons verticalAlign>
                {editButtons.map(
                  (editInfo) =>
                    editInfo.display &&
                    canManage && (
                      <Button variant="secondary" onClick={() => editInfo.onClick()}>
                        <Translate name={editInfo.text} />
                      </Button>
                    ),
                )}
              </ActionButtons>
            </>
          </BoxedContent>
        );
      })}
      <InvestorEditModals
        id={investor?.id || ''}
        naturalPerson={naturalPerson as NaturalPerson}
        legalPerson={legalPerson as LegalPerson}
        rootPerson={rootPerson as NaturalPerson | LegalPerson}
        fetchInvestorDetails={fetchInvestorDetails}
        investments={investments || []}
        benefitingOwners={benefitingPersons}
        target2Bic={investor?.target2Bic}
        {...investorDataProps}
      />
    </>
  );
};

export default UserDataView;
